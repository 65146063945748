.csd-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

:root {
  --gray-light: #dee2e6;
}

.csd-header {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 1em;
  color: #6e7075;
  line-height: 38px;
}

.csd-header-left {
  display: inherit;
  flex: 1 1 auto;
}

h2.csd-header-course {
  display: flex;
  align-items: center;
  font-size: 15px;
  padding-right: 2em;
  border-right: 1px solid lightgray;
}

.csd-filter-label {
  padding-right: 8px;
}

.csd-selector {
  font-size: 14px;
}

.csd-selector.subject {
  padding-left: 1em;
}

.csd-selector.timeframe {
  margin-left: 3em;
}

.csd-selector .label {
  padding-right: 8px;
  &:focus-within {
    color: darkblue;
    animation: ease-in-out;
  }
}

.csd-selector > div {
  display: inline-block;
}

.csd-react-select {
  width: 200px;
  font-size: 16px;
  color: #6e7075;
  & div:focus-within {
    background-color: rgb(209, 233, 242) !important;
  }
}

input[id^="react-select"]:focus-visible {
  background-color: rgb(209, 233, 242) !important;
}

// "csdrs" prefix is short-hand for class summary dashboard react-select
.csdrs__single-value {
  color: inherit !important;
}

.csdrs__indicator-separator {
  display: none !important;
}

.csdrs__control {
  line-height: initial !important;
}

.csdrs__menu {
  margin-top: 0px !important;
}

.csdrs__option {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.csd-num-students {
  padding-left: 2em;
  font-weight: bold;
}

.csd-students-icon {
  padding-right: 8px;
  color: #454545;
}

.csd-student-count {
  font-size: 12px;
}

.csd-columns {
  height: 490px;
  flex: 1 1 auto;
  display: flex;
  padding: 1em;
  justify-content: space-between;
}

.cs-column {
  display: flex;
  flex-direction: column;
  width: 33%;
  border: 2px solid lightgray;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  padding-bottom: 6px;
  height: 490px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.table-wrapper {
  height: 236px;
}

.column-top-section {
  height: 252px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #f5f4f2;
}

h1.cs-column-title {
  text-align: center;
  font-size: 20px;
  color: #1f2163;
  text-decoration: none solid rgb(31, 33, 99);
  text-align: center;
  padding: 10px;
}

.sfo-header {
  font-size: 20px;
  color: #1f2163;
  text-decoration: none solid rgb(31, 33, 99);
  text-align: center;
  height: 52px;
}

.student-table {
  flex: 1 1 auto;
  padding: 0.5em;
  height: 195px;
  overflow-y: auto;
}

.student-row {
  padding-bottom: 15px;
  padding-top: 15px;
  border-bottom: 1px solid lightgray;
  align-self: center;
}

.student-row-spanish-indicator {
  display: inline;
  height: 15px;
  margin-left: 20px;
  padding-left: 5px;
  padding-right: 5px;
  border-style: solid;
  border-width: thin;
  border-radius: 10px;
  text-align: center;
  font-weight: lighter;
}

.student-name {
  display: flex;
  justify-content: space-around;
  justify-content: space-between;
  align-self: center;
  height: 16px;
}

.name-color {
  color: #6e7075;
  float: left;
  font-weight: bold;
  font-size: 16px;
  padding-left: 25px;
  flex-basis: 50%;
  align-self: center;
  text-align: left;

  &:hover {
    text-decoration: underline;
  }

  &.clickable {
    border: 0;
    background-color: transparent;
    transition: all 0.2s ease-in;
    &:hover {
      color: rgb(44, 122, 152);
    }
  }
}

.lesson-color {
  color: rgb(87, 82, 82);
  float: right;
  font-size: 16px;
  overflow: auto;
  align-self: center;
  flex: 1 1 auto;
}

.student-menu {
  font-weight: bold;
  color: #3c95b9;
  font-size: 20px;
  align-self: center;
  border-width: 0;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background-color: inherit;
  transition: all 0.2s ease-in;
  margin-top: -10px;
  &:hover {
    background-color: rgb(209, 233, 242);
  }
  &:focus {
    outline: 1px solid #3c95b9;
    background-color: rgb(209, 233, 242);
  }
  &:after {
    display: none;
  }
}

.student-menu-open {
  position: fixed !important;
}

.clickable {
  cursor: pointer;
  align-self: center;
}

.view-all-button {
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  background-color: #8781bd;
  background-size: cover;
  font-size: 14px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
  border-radius: 4px;
  height: 37px;
  bottom: 0;
  &.clickable {
    border: 0;
  }
}

.details-columns-title {
  padding-left: 25px;
  font-size: 12px;
  color: #1f2163;
  text-decoration: none solid rgb(31, 33, 99);
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 30px;
  padding-bottom: 15px;
}

.hidden {
  visibility: hidden;
}

.student-detail-drop-heading {
  height: 50px;
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  color: #55555e;
  font-size: 14px;
  border: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  background-color: rgba(110, 112, 117, 0.05);
  background-size: cover;
  padding-left: 66px;
  padding-right: 66px;
}

.bold-font-weight {
  font-weight: bold;
}

.detail-name-style {
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 60px;
  flex-basis: 100%;
  justify-content: left;
  text-align: left;
  font-size: 16px;
  color: #2e7f9e;
  text-decoration: none solid rgb(46, 127, 158);
  font-weight: bold;
}

.padding-top-8px {
  padding-top: 8px;
}

.float-right {
  float: right;
}

.detail-overflow-menu {
  color: #2e7f9e;
}

.circle {
  background-position: center;
  font-weight: bold;
  display: inline-block;
  background-size: cover;
  font-family: Arial;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none solid rgb(255, 255, 255);
  text-align: center;
  border-radius: 30px;
  border-radius: 100%;
  width: 26px;
  height: 26px;
}

.red-circle {
  background-color: #f4473c;
}

.orange-circle {
  background-color: #f78d26;
}

.passed-time-columns {
  border-radius: 30px;
  background-size: cover;
  font-size: 16px;
  color: #55555e;
  text-decoration: none solid rgb(85, 85, 94);
  text-align: center;
}

.no-circle {
  height: 26px;
}

.lesson-table {
  flex: 1 1 auto;
  min-height: 300px;
  padding: 0.1em;
}

.lfo-aux-text {
  padding: 10px;
}

.lesson-flex-container {
  display: flex;
  padding-bottom: 0.11em;
  padding-top: 0.11em;
  align-items: center;
}

.slmenu {
  padding: 0 8px;
}

.lesson-row {
  display: flex;
  flex-basis: 100%;
  border: 0.5px solid #d6d6d6;
  border-radius: 4px;
  background-color: #ffffff;
  min-height: 49px;
  margin-left: 10px;
  justify-content: center;
  align-items: center;
  &:last-child {
    margin-right: 10px;
  }
}

.selected-lesson-row {
  border: 1px solid #8781bd;
}

.lesson-row:hover {
  border: 1px solid #8781bd;
}

.lesson-row:hover .lesson-name {
  color: #8781bd;
}

.lesson-row:hover .lesson-count-button {
  background-color: #8781bd;
}

.lesson-name {
  flex: 1 1 auto;
  display: flex;
  margin-left: 8px;
  font-size: 16px;
  color: #6c757d;
  text-decoration: none solid rgb(16, 115, 201);
  text-align: center;
}

.lesson-count-button {
  border: 1px solid white;
  float: right;
  display: flex;
  width: 38px;
  height: 38px;
  margin-right: 6px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  background-color: #6c757d;
  border-radius: 4px;
}

.selected-lesson-row .lesson-count-button {
  background-color: #2e3192;
}

.selected-lesson-row .lesson-name {
  color: #2e3192;
}
.rectangle {
  border-radius: 5px;
  background-color: #c4c4c4;
  width: 110px;
  height: 40px;
  display: inline-grid;
  align-items: center;
  text-align: center;
  color: white;
  background-position: center;
}

.lesson-menu {
  font-weight: bold;
  color: rgb(4, 155, 224);
  font-size: 20px;
  padding-left: 4px;
  padding-right: 4px;
}

.lessons-drill-down-name {
  justify-content: left;
  text-align: left;
  flex-basis: 100%;
  padding-right: 20px;
}

.lessons-drill-down-time,
.lessons-drill-down-grade-level,
.lessons-drill-down-standards {
  justify-content: center;
  text-align: center;
  flex-basis: 25%;
  padding-left: 5px;
}

.lessons-drill-down-domain {
  justify-content: left;
  text-align: left;
  flex-basis: 100%;
  padding-right: 40px;
}

.lessons-drill-down-score {
  justify-content: center;
  text-align: center;
  flex-basis: 25%;
  padding-left: 10px;
}

.lessons-drill-down-status {
  justify-content: left;
  text-align: left;
  flex-basis: 50%;
  padding-right: 20px;
}

.lessons-drill-down-student-name {
  justify-content: left;
  text-align: left;
  padding-left: 25px;
  flex-basis: 25%;
}

.just-align-float-center-basis-100 {
  justify-content: center;
  text-align: center;
  flex-basis: 100%;
  float: center;
}

.drill-down-heading-lfo {
  display: flex;
  border: 1px solid rgb(221, 219, 219);
  font-weight: bold;
  background-color: #f5f4f2;
  padding: 12px;
  font-size: 12px;
  color: #6e7075;
  text-decoration: none solid rgb(110, 112, 117);
  text-transform: uppercase;
}

.drill-down-heading-stfo {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(221, 219, 219);
  font-weight: bold;
  background-color: #f5f4f2;
  font-size: 12px;
  color: #6e7075;
  text-decoration: none solid rgb(110, 112, 117);
  text-transform: uppercase;
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.student-drill-down-style {
  padding-top: 20px;
  padding-left: 20px;
  background-color: #f5f4f2;
  padding-bottom: 20px;
}

.drill-down-data-stfo {
  display: flex;
  color: light grey;
  background-color: #f5f4f2;
  font-size: 14px;
  color: #757575;
  text-decoration: none solid rgb(117, 117, 117);
}

.drill-down-data-lfo {
  display: flex;
  color: light grey;
  background-color: #f5f4f2;
  font-size: 14px;
  font-family: Arial;
  font-weight: bold;
  color: #757575;
  text-decoration: none solid rgb(117, 117, 117);
  padding-top: 15px;
}

.in-progress-style {
  font-weight: bold;
}

.display-flex-padding-left-5px {
  display: flex;
  padding-left: 5px;
}

.display-flex {
  display: flex;
}

.display-flex-padding-left-5px-center-justify {
  display: flex;
  padding-left: 5px;
  justify-content: center;
  align-items: flex-start;
}

.sfo-details-table-wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.csd-details-header {
  line-height: 50px;
  min-height: 50px;
  height: 50px;
  font-size: 16px;
}

.csd-details-title {
  display: inline-block;
  padding-left: 1em;
}

.csd-details-back {
  display: inline-block;
  min-width: 50px;
  text-align: center;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  border-right: 1px solid lightgrey;
}

.sort-button {
  border: 0;
  background-color: transparent;
}

.csd-ux-modal .modal-header {
  padding: 0px;
  border-bottom: none;
}

.csd-ux-modal .modal-title {
  line-height: initial;
}

.modal-open .csd-ux-modal.modal {
  overflow-y: hidden;
  padding-left: 0px !important;
}

.csd-ux-modal .modal-body {
  height: 100%;
  padding: 0rem;
}

.csd-ux-modal .table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
}

.csd-ux-modal .table-heading {
  border-bottom: none !important;
}

.csd-ux-modal .py-2 {
  padding-top: 0rem !important;
}

.csd-ux-modal .accordion.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  padding-left: 1rem !important;
}

.csd-ux-modal .modal-content,
.csd-ux-modal .modal-dialog-scrollable,
.csd-ux-modal .modal-dialog-scrollable .modal-content {
  max-height: none;
}

.csd-ux-modal .table-separated {
  color: rgba(85, 85, 94, 0.5);
}

.padding-before-sort {
  padding-right: 10px;
}

.dots-color {
  color: grey;
}

.usage-detail-data {
  font-size: 16px;
  color: #55555e;
}

.usage-student-active-time {
  padding-right: 60px;
}

.usage-detail-active-time {
  padding-left: 50px;
  justify-content: center;
  text-align: center;
  float: center;
}

.no-students-font {
  background-size: cover;
  font-size: 16px;
  color: #6e7075;
  text-decoration: none solid rgb(110, 112, 117);
  text-align: center;
  padding-top: 25px;
  display: block;
}

.App {
  padding-bottom: 10px;
  height: 200px;
  position: relative;
}

.google-pie-chart {
  height: 200px;
}

.custom-tooltip {
  --tooltip-visibility: hidden;
  font-size: 12px;
  background-color: #fff;
  box-shadow: 1px 1px 5px 1px #808080;
  position: absolute;
  z-index: 1;
  padding: 6px;
  line-height: 1.3;
  width: 120px;
  left: 0;
  right: 0;
  margin: 0 auto;
  visibility: var(--tooltip-visibility);

  &_bold {
    font-weight: bold;
  }
}

@media screen and (max-width: 960px) {
  .csd-header {
    border: solid 1px #f5f4f2;
  }

  .csd-num-students {
    border-right: 1px solid lightgray;
    padding-top: 1px;
    padding-left: 1em;
    padding-right: 1em;
  }

  .csd-header-course {
    display: none;
  }

  .csd-columns {
    flex-direction: column;
  }

  .cs-column {
    flex-direction: row;
    margin-bottom: 15px;
    width: 100%;
    min-height: 374px;
    height: 100%;
    border: none;
  }

  .view-all-button {
    border-radius: 0;
  }

  .column-top-section,
  .table-wrapper {
    width: 50%;
  }

  .column-top-section {
    height: 374px;
  }

  .table-wrapper {
    margin-left: 10px;
    border: solid 2px #f5f4f2;
    border-radius: 8px;
    height: 374px;
  }

  .student-table {
    height: 337px;
  }

  .view-all-button {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}

@media screen and (max-width: 640px) {
  .csd-react-select {
    width: 142px;
  }

  .name-color {
    font-size: 14px;
  }
}
