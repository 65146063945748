.scr-nav-sub-heading-container {
  width: 100%;
  background: white;
  position: fixed;
  z-index: 2;
  margin: 0 auto;
  height: 70px;
  .scr-nav-sub-heading {
    color: white;
    box-shadow: 0px 10px 23px -14px #3f3f3f;
    display: flex !important;
    align-items: center;
    background: linear-gradient(0deg, #ebeaea 0%, #ffffff 50%, #ebeaea 100%);
    height: 100%;
    h3 {
      font-size: 26px;
      color: #d53e0b;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
    }
    img {
      width: 70px;
    }
    & > div {
      margin-left: 2rem;
    }
    .scr-visual-action-image {
      margin-left: 0;
      & + div {
        margin-left: 0;
      }
    }
  }
}
