.lrn {
  .lrn-dragdrop-container {
    .lrn_possibilityList,
    .lrn_possibilityListContainer {
      padding: 14px 0 0 14px;
      &.lrn_list_bottom {
        margin-top: 7px;
      }
    }
    .lrn_possibilityList {
      border: none;
      padding: 14px 0 0 14px;
    }
    .lrn_possibilityListContainer {
      border: none;
      padding: 0;
    }
    .lrn_btn_drag > span {
      margin-left: 0em;
    }
  }
}

.lrn_widget {
  .lrn-dragdrop-container {
    .lrn_response_input_wrapper {
      border: none;
    }
    .lrn_qr.lrn_association .lrn_response .lrn_assoc_table {
      padding-top: 0;
      border-spacing: 0 14px;
    }
  }
  &.lrn_classification .lrn_response .lrn_response_input_wrapper .lrn-dragdrop-container {
    table {
      margin: 0 0 calc(14px + 12px) 0;
      padding: 0 14px 0 14px;
      /* After items have been dropped, the container should have padding top+left (and the children bottom and right) */
      th {
        padding: 12px;
      }
      td {
        padding: 12px 0 0 12px;
      }
    }
  }
  &.lrn_imageclozeassociationV2 {
    /* The original rule (which the one below overrides) includes:
           &.lrn_imageclozeassociationV2 {.lrn_response_wrapper .lrn_btn_drag.lrn_drag_mirror{}}*/
    /*&.lrn_imageclozeassociationV2 {.lrn_response_wrapper .lrn_dropzones_container .lrn_dragdrop .lrn_btn_drag{}} */
    .lrn_response_input_position {
      padding: 14px 0 0 14px;
    }
    .lrn_response_wrapper .lrn_possibilityList.lrn_dragdrop .lrn_btn_drag {
      padding: 0;
      margin: 0 14px 14px 0;
    }
  }
  &.lrn_classification {
    .lrn-dragdrop-container .lrn_possibilityList .lrn_draggable {
      margin: 0 0.3em;
    }
  }
  &.lrn_clozeassociation,
  &.lrn_imageclozeassociation,
  &.lrn_imageclozeassociationV2 {
    .lrn-dragdrop-container {
      .lrn_response_input {
        margin: 0 14px 14px 0; /* right+bottom for items */
        padding: 0;
        .lrn_response_container.lrn_dragdrop {
          margin: 0; /* right+bottom for items */
        }
      }
      .lrn_possibilityList {
        .lrn_draggable {
          margin: 0 14px 14px 0;
        }
        &.lrn_list_top,
        &.lrn_list_bottom {
          min-height: 75px; /* smallest seen thus far 3rd grade Math, single line */
        }
      }
    }
  }
  &.lrn_clozeassociation {
    .lrn-dragdrop-container .lrn_response_input {
      margin: 0;
      padding: 14px 0 0 14px; /* top+left for containers */
      line-height: 1;
      & > * {
        margin: 0 14px 14px 0; /* right+bottom for items */
        padding: 0;
        & > table {
          margin-bottom: 0;
        }
      }
    }
  }
}
