@import "lrn-widget/drag-drop";

.content-reader-wrapper {
  $playerSize: 1200px;
  $panelSize: 600px;
  $heightWrapper: calc(100% - 145px);
  $heightSegmentWrapper: calc(100vh - 145px);
  $heightSegmentWrapperWithNav: calc(100vh - 215px);
  $maxWidthLayoutVideo: calc((100vh - 190px) * 1.56);
  $maxWidthFeedbackVideo: calc((100vh - 190px) * 1.56);
  $maxWidthLayoutWithTranscripts: calc((100vh - 190px) * 2.35);
  $maxWidthForSmallVideo: calc((100vh - 190px) * 1.8);
  $maxWidthTranscripts: calc((100vh - 190px) * 2.7);
  $maxWidthLayoutSlideshow: calc((100vh - 190px) * 1.38);

  height: $heightWrapper;
  overflow-y: auto;
  max-width: 100%;

  &.nav-active {
    .wnr-app-app {
      overflow-y: auto;
      margin-top: 70px;
    }

    .wnr-app-container {
      &.wnr-app-has-sidebar {
        max-width: 80%;

        .wnr-app-sidebar {
          background-color: var(--white) !important;
        }
      }
    }

    .wnr-app-content {
      height: $heightSegmentWrapperWithNav;
      .wnr-contentLayout-empty-bar:first-child + div {
        display: none;
      }
    }
  }

  .wnr-app-panel-area {
    width: $panelSize;
    right: -$panelSize;
    height: $heightWrapper;
    position: fixed;
    top: 80px;
    .wnr-learningPathPanel-header {
      padding: 20px;
      border-bottom: 1px solid var(--gray-light);
      width: 100%;
      position: fixed;
      background: var(--white);
      z-index: 2;
      h2 {
        font-family: "Century Gothic", sans-serif;
        margin: 0 0 0 8px !important;
      }
      & + div {
        padding: 20px;
        margin: 60px 0 30px 0;

        .wnr-ccmedia-media-media-container-left {
          display: block;
        }

        .wnr-contentComponent-container {
          font-family: "Century Gothic", sans-serif;
          font-size: 24px;
        }
      }
    }

    .wnr-learningPathPanel-container {
      padding-top: 0px;
    }

    .wnr-learningPathPanel-close-button {
      margin-top: 10px;
      margin-right: 40px;
      position: fixed;
      z-index: 3;
      top: 95px;
      right: 0;
      transition: 0.5s all;
    }

    &.wnr-app-hide {
      .wnr-learningPathPanel-close-button {
        opacity: 0;
      }
    }

    &.wnr-app-show.wnr-app-animation-complete {
      .wnr-learningPathPanel-close-button {
        opacity: 1;
      }
    }

    .wnr-learningPathPanel-content {
      padding: 0;
    }
    // Fix button position with media
    .wnr-contentLayout-layout {
      display: flex;
      flex-direction: column;
      .wnr-contentRow-row {
        flex-grow: 1;
        margin: 0;
        padding: 0;
        .wnr-contentCell-cell {
          padding: 0;
          &.wnr-contentCell-attached-and-numbered {
            display: flex;
            align-items: flex-start;
            .wnr-contentCell-shifted {
              position: relative;
              top: 0;
              left: 0;
              margin: 15px 0 0 0;
            }
          }
          &.wnr-contentCell-RichText {
            padding-left: 2.3rem;
          }
        }
        .wnr-ccimg-image-image-container {
          padding-left: 3rem;
        }
        .wnr-ccmedia-media-mini-button {
          &.wnr-ccmedia-media-mini-button-attached {
            display: flex;
            margin-left: 0;
            position: relative;
            left: 0;
            margin-bottom: -3rem;
          }
        }
      }
    }
  }

  .wnr-contentLayout-layout {
    max-width: $playerSize !important;
    .wnr-contentRow-row {
      .wnr-contentCell-cell {
        .wnr-contentCell-left-buttons {
          top: 15px;
          margin-left: -3rem;
          &.wnr-contentCell-shifted {
            margin-left: -2rem;
          }
        }
        .wnr-ccmedia-media-mini-button {
          &.wnr-ccmedia-media-mini-button-attached {
            top: unset;
          }
        }
        &.wnr-contentCell-RichText {
          padding-left: 3rem;
        }
      }
    }
  }
  .wnr-segment-content-area-container {
    .wnr-contentLayout-layout {
      .wnr-contentCell-left-buttons {
        left: 45px;
        top: 18px;
        margin: 0;
      }
    }
  }

  .wnr-ccmedia-media-video {
    display: none;
    &.loaded {
      display: block;
    }
  }

  .wnr-contentLayout-layout-type-video {
    max-width: $maxWidthLayoutVideo !important;
  }

  .wnr-contentLayout-layout-type-Slideshow {
    max-width: $maxWidthLayoutSlideshow !important;
  }

  .wnr-contentLayout-layout-type-cc-spotlight-view {
    max-width: $maxWidthFeedbackVideo !important;
  }

  .wnr-contentLayout-empty-bar {
    height: unset !important;
  }

  .wnr-ccquestion-distractor-rationale,
  .wnr-ccimg-image-container,
  .wnr-ccmedia-media-container {
    background-color: var(--white) !important;
    border: 0 !important;
  }

  .wnr-ccimg-image-caption,
  .wnr-ccmedia-caption-caption {
    background-color: var(--white) !important;
  }

  .wnr-ccquestion-question-container {
    background-color: var(--white) !important;
    border: 0 !important;

    .lrn_question {
      background-color: #d5ebfc;
      padding: 1.25rem;
      border-radius: 10px;
    }

    .lrn_response_wrapper {
      label.lrn-label {
        background-color: var(--white) !important;

        &.wnr-readaloud-reading {
          box-shadow: 0px 0px 0px 3px #0a2a66 !important;
          border-radius: 10px;
        }

        &.wnr-readaloud-reading:after {
          display: none !important;
        }

        .lrn_choiceLabel {
          background-color: var(--gray-extra-light) !important;
          color: var(--black) !important;
          border: 0;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        .lrn_singleRow.lrn_contentWrapper {
          border-bottom-right-radius: 10px;
          border-top-right-radius: 10px;
          border-color: var(--gray-light);
        }
      }

      .wnr-readaloud-audio-marker {
        &:before,
        &:after {
          opacity: 0 !important;
        }
      }
    }
  }

  /* Removes excess spacing in doolittle */
  .wnr-segmentTitle-title-bar {
    display: none;
  }

  .wnr-segment-spacer {
    display: none;
  }

  .wnr-segment-content .wnr-segment-layout {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .wnr-segment-content .wnr-segment-layout.wnr-contentLayout-layout-type-video.video-container-resize {
    max-width: $maxWidthForSmallVideo !important;
  }

  .wnr-segment-content .wnr-segment-layout.wnr-contentLayout-layout-type-video.video-with-transcripts {
    max-width: $maxWidthLayoutWithTranscripts !important;
  }

  .wnr-segment-content .video-container-resize.wnr-contentLayout-layout-type-video.video-with-transcripts {
    max-width: $maxWidthTranscripts !important;
  }
  // remove once content gets tagged with video layout
  .wnr-segment-content .wnr-segment-layout.video-with-transcripts {
    max-width: $maxWidthLayoutWithTranscripts !important;
  }

  .wnr-contentCell-read-aloud-button,
  .wnr-ccmedia-media-mini-button,
  .wnr-readaloud-audio-marker {
    background-color: #007acc;
  }

  ​.wnr-contentCell-read-aloud-button:hover,
  .wnr-ccmedia-media-mini-button:hover,
  .wnr-readaloud-audio-marker:hover {
    background-color: gray;
  }

  ​.wnr-contentCell-left-buttons {
    display: none !important;
  }

  ​.wnr-readaloud-audio-marker {
    height: 32px;
    width: 32px;
  }

  .lrn_response_wrapper,
  .lrn-dragdrop-container {
    .wnr-readaloud-audio-marker {
      background: none;
      outline: 0;
    }

    .wnr-readaloud-loading {
      visibility: hidden;
    }
  }

  .scroll-indicator {
    z-index: 1030;
    float: right;
    position: fixed;
    right: 15px;
    bottom: 64px;
    visibility: visible;
    cursor: pointer;
    display: none;
  }

  .bounce {
    animation-name: bounce-5;
    animation-timing-function: ease;
    animation-iteration-count: 1;
    animation-duration: 2s;
  }
  @keyframes bounce-5 {
    0% {
      transform: scale(1, 1) translateY(0);
    }
    10% {
      transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
      transform: scale(0.9, 1.1) translateY(-20px);
    }
    50% {
      transform: scale(1, 1) translateY(0);
    }
    57% {
      transform: scale(1, 1) translateY(-7px);
    }
    64% {
      transform: scale(1, 1) translateY(0);
    }
    100% {
      transform: scale(1, 1) translateY(0);
    }
  }

  .student-k-2 {
    .wnr-contentComponent-container,
    .lrn_response,
    .lrn_question,
    .lrn_sharedpassage {
      font-family: "Century Gothic", sans-serif;
      font-size: 1.5rem;
    }
  }

  .student-3-5 {
    .activity-type {
      text-transform: uppercase;
    }

    .wnr-contentComponent-container,
    .lrn_response,
    .lrn_question,
    .lrn_sharedpassage {
      font-family: "Roboto", sans-serif !important;
      font-size: 1.15rem;
    }
    .wnr-learningPathPanel-header {
      h2,
      .wnr-contentComponent-container {
        font-family: "Roboto", sans-serif !important;
      }
    }
  }
  .student-6-8,
  .student-9-12 {
    .wnr-contentComponent-container,
    .lrn_response,
    .lrn_question,
    .lrn_sharedpassage,
    .lrn_response_innerbody.lrn-response-validate-wrapper {
      font-family: "Roboto", sans-serif !important;
      font-size: 1rem;
    }
  }

  .wnr-ccquestion-question-submit-container {
    .wnr-ccquestion-question-submitted-label {
      visibility: hidden;
    }
  }

  .wnr-segment-content {
    .wnr-segment-has-passage {
      max-width: $playerSize;
      margin: 0 auto;
      height: $heightSegmentWrapper;
      .wnr-learningPathPanel-container-passage,
      .wnr-segment-content-area-container {
        overflow-x: scroll;
        min-width: 50%;
      }
      .wnr-ccquestion-question-container {
        width: 88%;
      }
    }
  }
  // Remove yellow background correct answers
  .lrn_mcqgroup-horizontal .lrn-mcq-option.lrn_valid {
    background-color: transparent;
  }
  .lrn-response-validation-wrapper,
  .lrn_response_wrapper {
    .lrn_correctAnswers {
      background-color: transparent;
    }
  }
  //Hide Radio button
  .lrn_widget.lrn_mcq .lrn_response {
    .lrn_mcqgroup.lrn_mcqgroup-horizontal,
    .lrn_mcqgroup.lrn_mcqgroup-vertical,
    .lrn_mcqgroup.lrn_mcqgroup-block {
      ul {
        margin-right: -16px !important;
      }

      li {
        &.lrn-mcq-option {
          border-right: 16px solid transparent;
          border-bottom: 16px solid transparent;
          border-top: 0;
          border-left: 0;
          padding: 0;
          margin: 0;
          .lrn-input {
            opacity: 0;
            &:focus + label {
              box-shadow: unset;
            }
          }
          /* MCQAC Label - Default state */
          span.lrn_choiceLabel {
            display: table-cell;
            padding: 14px;
            font-size: 32px;
            max-height: none;
            min-width: 21px;
            max-width: none;
            width: auto;
            vertical-align: middle;
            border: 3px solid transparent !important;
            border-right: 0 !important;
            box-sizing: content-box;
            overflow: hidden;
            text-align: center;
          }
          /* MCQ Answer Choice with label - Default state */
          .lrn_contentWrapper.lrn_singleRow {
            border-top: 3px solid var(--gray-extra-light) !important;
            border-right: 3px solid var(--gray-extra-light) !important;
            border-bottom: 3px solid var(--gray-extra-light) !important;
            border-left: 0 !important;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
          }
        }

        &.lrn-mcq-option.lrn_selected {
          /* MCQAC Label - Selected state */
          span.lrn_choiceLabel {
            border-color: #0a2a66 !important;
          }
          /* MCQ Answer Choice with label - Selected state */
          .lrn_contentWrapper.lrn_singleRow {
            border-color: #0a2a66 !important;
          }
        }

        &.lrn-mcq-option.lrn_correct {
          span.lrn_choiceLabel {
            border: 3px solid #4a700b !important;
            border-right: 0 !important;
          }
        }

        &.lrn-mcq-option.lrn_incorrect {
          span.lrn_choiceLabel {
            border: 3px solid #dc002f !important;
            border-right: 0 !important;
          }
        }

        .lrn-label:hover {
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          border-radius: 10px;
        }
        .lrn-label {
          padding: 0 !important;
        }
        &.lrn-mcq-option .lrn_contentWrapper {
          border: 3px solid #949494 !important;
          border-radius: 10px;
          padding: 7px;
          line-height: 1.5;
          .wnr-readaloud-audio-marker {
            line-height: 1;
          }
        }
        &.lrn_selected {
          background: none;
        }

        &.lrn_selected .lrn_singleRow.lrn_contentWrapper {
          border: 3px solid #0a2a66 !important;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-left: 0 !important;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
        &.lrn-mcq-option.lrn_correct .lrn_singleRow.lrn_contentWrapper {
          border: 3px solid #4a700b !important;
          border-left: 0 !important;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
        &.lrn-mcq-option.lrn_incorrect .lrn_singleRow.lrn_contentWrapper {
          border: 3px solid #dc002f !important;
          border-left: 0 !important;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
        &.lrn_selected .lrn_contentWrapper {
          border: 3px solid #0a2a66 !important;
        }
        &.lrn-mcq-option.lrn_correct .lrn_contentWrapper {
          border: 3px solid #4a700b !important;
        }
        &.lrn-mcq-option.lrn_incorrect .lrn_contentWrapper {
          border: 3px solid #dc002f !important;
        }
      }
    }

    .lrn_mcqgroup-block {
      li {
        &.lrn-mcq-option {
          border-right: 0;
          padding: 0 8px !important;
        }
      }
    }
  }

  .lrn_widget.lrn_clozeassociation,
  .lrn_widget.lrn_imageclozeassociation,
  .lrn_widget.lrn_imageclozeassociationV2 {
    .lrn_possibilityList,
    .lrn_response {
      .lrn_draggable {
        &:focus {
          border: 2px solid #0a2a66;
        }
      }
    }
  }
  .wnr-marginNotes-notes {
    .wnr-marginNotes-note {
      margin: 0;
      padding: 0;
    }
  }
}

// Focus on buttons - math keyboard
.lrn_widget {
  .lrn-formula-keyboard-menu-right .lrn-formula-keyboard-menu-container,
  .lrn-formula-keyboard-close {
    button:focus {
      border: 2px solid #289af0 !important;
    }
  }
  .lrn-formula-keyboard-close button {
    padding: 1px 4px;
  }
  .lrn-formula-keyboard {
    .lrn_btn_grid:focus {
      box-shadow: none;
      border: 2px solid white;
      border-radius: 6px;

      &:after {
        border: 2px solid white !important;
        bottom: 3px !important;
        left: 3px !important;
        right: 3px !important;
        top: 3px !important;
        box-shadow: 0 0 0 2pt #289af0;
      }
    }
  }
}

// Hide Learnosity default
.wnr-contentComponent-container,
.wnr-ccquestion-question-container {
  .lrn_response {
    .lrn_response_input_wrapper {
      &.lrn_incorrect,
      &.lrn_correct {
        border: 0 !important;
      }
      &:after {
        content: unset !important;
      }
    }
    .lrn-response-validation-wrapper {
      .lrn_cloze_response_container {
        .lrn-response-validate-wrapper {
          &.lrn_incorrect,
          &.lrn_correct {
            border: 0 !important;
          }
          &:after {
            content: unset !important;
          }
        }
      }
    }
    .lrn-response-validate-wrapper {
      border: unset !important;
      &:after {
        content: unset !important;
      }
    }
  }
  .lrn_widget {
    .lrn_question {
      padding: 1rem;
      .lrn_stimulus {
        .lrn_stimulus_content > *:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .wnr-ccquestion-question-submit-container {
    display: none;
  }
}

.content-reader {
  /* Removes question background */
  .wnr-ccquestion-item-item-container {
    border: none !important;
    .wnr-ccquestion-item-item-question-section {
      background-color: transparent !important;
    }
  }

  .lrn_question .wnr-readaloud-reading:after {
    top: -1rem;
    border-radius: 10px;
    left: -1rem;
    width: calc(100% + 2 * 1rem);
    height: calc(100% + 2 * 1rem);
  }

  .lrn_btn_drag.wnr-readaloud-reading:after {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

#dolittle-load-error {
  padding: 1rem;
  color: var(--danger);
}

// Science - 2 column large text layout
.wnr-contentLayout-layout-type-2column_largeText {
  .wnr-contentComponent-type-RichText {
    font-weight: bold;
  }
}

@mixin fontSizeMixin($size, $lineHeight) {
  .wnr-contentLayout-layout-type-2column_largeText .wnr-contentComponent-type-RichText span {
    font-size: $size !important;
    line-height: $lineHeight;
  }
}

.student-k-2 {
  @include fontSizeMixin(2.25rem, 2.75rem);
}

.student-3-5 {
  @include fontSizeMixin(2rem, 2.5rem);
}

.student-6-8,
.student-9-12 {
  @include fontSizeMixin(1.625rem, 2rem);

  .wnr-contentLayout-layout-type-2column_largeText .wnr-contentComponent-type-RichText * {
    font-family: Arial, Helvetica, sans-serif;
  }
}

// feedback video changes
.wnr-contentLayout-layout-type-cc-spotlight-view,
.wnr-contentLayout-layout-type-subhead_with_icon,
.wnr-contentLayout-layout-type-subhead_without_icon {
  .wnr-ccquestion-distractor-rationale-title,
  .wnr-contentCell-read-aloud-button {
    display: none !important;
  }
}
