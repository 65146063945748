.student-content-reader {
  .navbar-nav {
    .mpng-ux-action {
      margin: 0 !important;
      &.calculator-btn {
        margin-left: 8px !important;
      }
    }
  }
}

.navbar-brand {
  padding-top: 0px;
  padding-bottom: 0px;
}

// Footer buttons
.ft-btns {
  border: 3px solid #f8f9fa !important;
}

.ft-btns:hover {
  box-shadow: 0 0 0 3px #1865f3 !important;
}

.ft-btns:focus {
  box-shadow: 0 0 0 3px #1865f3 !important;
}

// Text to speech toolbar
.tts-toolbar,
.hl-toolbar {
  position: fixed;
  bottom: 4.8rem;
  left: 1rem;
  // background-color: var(--info);
  // border: 1px solid;
  z-index: 9999;
}
.play-toolbar {
  border: 3px solid white !important;
  position: relative;
}

.stop-toolbar {
  border: 3px solid white !important;
  position: relative;
  left: 3px;
}

.play-toolbar:focus {
  box-shadow: 0 0 0 3px #1865f3 !important;
}

.play-toolbar:hover {
  box-shadow: 0 0 0 3px #1865f3 !important;
}

.stop-toolbar:focus {
  box-shadow: 0 0 0 3px #1865f3 !important;
}

.stop-toolbar:hover {
  box-shadow: 0 0 0 3px #1865f3 !important;
}

.student-k-2 {
  .mpng-ux-header {
    font-size: 1.125rem;
  }

  .mpng-ux-navbar {
    line-height: 2rem;

    .mpng-ux-action {
      .tts-icon {
        width: auto;
      }
    }
  }

  .edge-tts-toolbar {
    bottom: 4.75rem;
  }

  .page-item {
    width: 2.9rem;
    height: 2.9rem;
  }
}

.student-3-5 {
  .activity-type {
    text-transform: uppercase;
  }

  .mpng-ux-header {
    font-size: 1rem;
  }

  .page-item {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.pagination {
  .page-item {
    border-radius: 50%;
    border: 2px solid;
    border-color: var(--primary) !important;
    padding: 0.05rem 0.5rem !important;
    font-weight: 650 !important;
    margin-right: 0.25rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  &.pagination-animation {
    transition: 1s all;
    opacity: 0;
    &.active {
      opacity: 1;
    }
  }

  .inactive-item {
    color: #000000 !important;
    background-color: #ffffff !important;
    border-color: #808080 !important;
  }

  .completed-item {
    color: #4a4a4a !important;
    background-color: #ecf3fc !important;
    border-color: #4c91e2 !important;
  }

  .mpng-ux-indicator {
    display: inline-flex;
    vertical-align: middle;
    margin-right: 0.25rem;
  }
}

.language-container {
  .selected-language-wrapper {
    .enhanced-icon-text {
      margin-right: 8px;
    }
  }
  .dropdown-menu {
    margin: 0px;
    width: 250px;
    max-height: 400px;
    overflow-x: auto;
    border: 1px Solid var(--gray);
    .dropdown-item {
      font-family: "Roboto";
      font-size: 24px !important;
      padding: 0 1rem;
      margin: 1rem 0;
      &.active-language {
        &:before {
          position: absolute;
          right: 1rem;
          content: "✓";
          font-weight: 600;
          color: var(--blue);
        }
      }
      &:active {
        color: inherit;
        background-color: inherit;
      }
      &:hover,
      &:focus {
        background-color: unset;
      }
    }
    .dropdown-divider {
      border-top: 1px Solid var(--gray);
    }
  }
}
.student-6-12-header,
.student-6-12-footer {
  .navbar-bg {
    background-color: var(--black) !important;
  }
}
.student-6-12-header {
  .activity-type {
    font-size: 0.75rem;
    text-transform: uppercase;
  }
  .selected-language-wrapper {
    .mpng-ux-action {
      background-color: unset;
      border: unset;
    }
  }
  .close-btn,
  .close-btn:hover {
    background-color: unset;
    border: unset;
  }
}
.student-6-12-footer {
  .navbar-nav:nth-child(1) {
    > div {
      display: flex;
    }
  }
  .pagination {
    .page-item {
      width: unset !important;
      background-color: unset !important;
      color: var(--white) !important;
      border: unset !important;
      font-size: 20px;
      &.active-item {
        border-radius: 5px;
        background-color: var(--purple) !important;
        border-color: var(--purple) !important;
      }
    }
  }
}

.sidekick-message {
  z-index: 1030;
  float: right;
  position: fixed;
  right: 5rem;
  bottom: 5rem;

  &.hide-sidekick {
    display: none;
  }
}

.sidekick-label {
  position: relative;
  float: left;
  border-radius: 1rem 0 1rem 1rem;
  background-color: #ffd41d;
  padding: 1.5rem;
  top: 10px;
}

.wnr-ccquestion-question-attempted-label {
  display: none !important;
}

.mpng-ux-logo-text {
  .logo-text-custom {
    color: #fff !important;
    &.with-icon-header {
      padding-left: 1rem;
      border-left: 1px solid white;
      margin-left: 1rem;
    }
  }
}

// resizing the header to fit the content on all screens
.student-content-reader {
  .navbar.header {
    line-height: 0.875rem !important;
    padding: 0.625rem 0;

    .navbar-nav {
      button.mpng-ux-action {
        font-size: 1.5rem;
        line-height: 0.875rem;
      }
    }

    .mpng-ux-logo-text {
      .logo-text-custom {
        font-size: 1.5rem;
        line-height: 2rem;
      }
    }

    .mpng-ux-header {
      text-transform: uppercase;
      font-size: 0.875rem !important;
      line-height: 1rem;
    }
  }
  .wnr-contentComponent-container,
  .wnr-ccquestion-question-container {
    .lrn_response {
      .lrn_validatedResponseList,
      .lrn_correctAnswers {
        display: none;
      }
    }
    .lrn_response_input_wrapper {
      .lrn_dropzones_container {
        .lrn_dropzone_wrapper {
          .lrn_responseIndex {
            display: none !important;
          }
        }
      }
    }
    .lrn-response-validation-wrapper {
      .lrn_cloze_response_container {
        .lrn-response-validate-wrapper {
          .lrn_responseIndex {
            display: none !important;
          }
        }
      }
    }
    .lrn-dragdrop-container {
      .lrn_dragdrop {
        .lrn_responseIndex {
          display: none !important;
        }
      }
    }
  }
}

.footer {
  .mpng-ux-preloader.threedots {
    background: var(--primary) !important;
    padding: 0.35em;
    border-radius: 0.3em;
    width: 12rem !important;

    .svg-loaders-svg {
      fill: #fff;
    }
  }
  .footer-action-btn {
    width: 12rem !important;

    .mpng-ux-enhanced-icon {
      justify-content: center;
    }
  }
}

.student-6-8,
.student-9-12 {
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #6e6e6e;
    background-color: #fff;
    border-color: #fff;
  }
}

.student-k-2,
.student-3-5 {
  .btn-primary.disabled,
  .btn-primary:disabled {
    color: #3d4450;
    background-color: #cdcdcd;
    border-color: #cdcdcd;
  }
}
