.educator-content-reader {
  .content-reader-wrapper {
    width: 100%;
  }

  .activity-dropdown button {
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--black) !important;
  }

  .carousel-control-next,
  .carousel-control-prev {
    width: 1rem !important;
    color: var(--purple-light) !important;
  }

  .carousel-control-next {
    right: -1rem !important;
  }

  .carousel-control-prev {
    left: -1rem !important;
  }

  .carousel-inner {
    display: inline-flex;
    white-space: nowrap;
  }

  .lesson-viewer-carousel-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 3rem;
    width: 3rem;
    padding: 1rem;
    margin: 1rem;
    color: var(--purple-light);
    background-color: var(--white);
    border: 2px solid var(--purple-light);
    border-radius: 50%;
    white-space: nowrap;
    &.complete {
      color: var(--purple-light);
      background-color: var(--white);
      border: 2px solid var(--purple-light);
      &.active {
        color: var(--white);
        background-color: var(--purple-light);
      }
    }
    &.incomplete {
      color: var(--purple-light);
      background-color: #fff;
      border: 2px solid var(--gray-light);
      &.active {
        color: #fff;
        background-color: var(--purple-light);
      }
    }
  }

  .icon-float {
    float: right;
    position: absolute;
    transform: translate(15px, 15px);
  }

  .item-correct {
    border: 1px solid var(--green);
    color: var(--green);
  }

  .item-incorrect {
    border: 1px solid var(--red);
    color: var(--red);
  }

  .item-inprogress {
    border: 1px solid var(--teal-light);
    color: var(--teal-light);
  }

  .item-backdrop {
    background-color: var(--gray-extra-light);
  }

  .item-wrapper {
    display: inline-flex;
  }

  .hr-small {
    color: var(--gray-extra-light);
    size: 1px;
    margin-bottom: 0;
  }

  .row {
    justify-content: space-between;
  }

  .scr-nav-sub-heading-container {
    position: relative !important;
  }
}
