.scroll-button {
  border: 1px solid transparent;
  background-color: transparent;
  opacity: 0.65;
  padding: 0.5rem 1rem;
  font-size: 1.75rem;
  line-height: 1.5;
  border-radius: 0.3rem;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  .scroll-button-text {
    span {
      color: #212529;
    }
  }
  .circle-icon-new {
    width: 40px;
    height: 40px;
    color: #fff;
    padding: 0.5rem !important;
    border-radius: 50% !important;
    background-color: #343a40 !important;
    vertical-align: -0.125em;
    path {
      color: #fff !important;
    }
  }
}
